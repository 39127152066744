export default {
  tabs: {
    common: 'Auftragsdaten',
    todos: 'Aufgaben',
    form: 'Formular',
    additionalForms: 'Unterformulare',
    subforms: 'Unterformulare',
    images: 'Bilder',
    documents: 'Dokumente',
    emails: 'E-Mails',
    files: 'Dateien',
    calculation: 'Kalkulation',
    report: 'Berichte',
    appointment: 'Termine',
    roomItems: 'Raumplan',
    invoices: 'Rechnungen',
    proposals: 'Angebote',
    statistics: 'Statistik',
    logs: 'Akte',
    customerPortal: 'Kundenportal',
  },
  statistic: {
    dialogs: {
      exportTask: {
        title: 'Statistik herunterladen',
        note: 'Laden Sie die exportierte Aufgabe und die dazugehörige Statistik herunter',
      },
    },
  },
  templateForm: {
    spellCheck: 'Rechtschreibprüfung',
    dialogs: {
      exportTask: {
        title: 'Formulardaten herunterladen',
        note: 'Laden Sie die Daten des aktuellen Formulars für die Weiterbearbeitung in einer Tabellenkalkulations-Software herunter.',
      },
      spellCheck: {
        title: 'Rechtschreibprüfung',
        note: 'Hier können Sie die Rechtschreibprüfung durchführen',
      },
    },
    mandatoryFieldsIndicator: {
      incompleteLabel: 'Pflichtfelder {{completedCount}}/{{totalCount}}',
      completeLabel: 'Pflichtfelder',
    },
  },
  customerPortal: {
    messages: {
      created: 'Meeting erfolgreich erstellt',
      updated: 'Meeting erfolgreich aktualisiert',
    },

    files: {
      dialogs: {
        documents: {
          title: 'Dokumente auswählen',
          description: 'Fügen sie Dokumente zu ihrem Meeting hinzu',
        },
      },
      files: {
        title: 'Bilder',
        teaser:
          'Hier finden Sie die Bilder, welcher der Kunde über seinen Kunden-Link hochgeladen hat. Diese können Sie her dann in Ihren Auftrag übernehmen.',
        labels: {
          accept: 'Bilder akzeptieren',
          decline: 'Bilder ablehnen',
        },
        grid: {
          columns: {
            file: 'Bild',
          },
        },
      },
      documents: {
        title: 'Dokumente',
        teaser:
          'Hier können Sie dem Kunden Dokumente zum Prüfen oder zur Unterschrift über seinen Kunden-Link zur Verfügung stellen.',
        labels: {
          choose: 'Dokumente auswählen',
          accept: 'Dokumente akzeptieren',
          decline: 'Dokumente ablehnen',
        },
        grid: {
          columns: {
            original: 'Original',
            modified: 'Bearbeitet',
            noThumbnail: 'Kein Vorschaubild',
          },
        },
      },
    },
    detail: {
      notFound: 'Meeting nicht gefunden.',
      data: {
        title: 'Meeting',
      },
      buttons: {
        join: {
          label: 'Videoraum beitreten',
          inFuture: 'Termin in: {{ duration }}',
          isNow: 'Termin ist jetzt',
          inPast: 'Termin war bereits',
        },
        copy: 'Link kopieren',
        sendSMS: 'SMS senden',
        sendEmail: 'E-Mail senden',
      },
      messages: {
        sendSMS: 'SMS wurde gesendet.',
        sendEmail: 'E-Mail wurde gesendet.',
      },
      labels: {
        from: 'Start',
        to: 'Ende',
        contactPersonEmail: 'Kontaktperson E-Mail',
        contactPersonName: 'Kontaktperson',
        contactPersonPhone: 'Kontaktperson Telefon',
        customerEmail: 'Kunden E-Mail',
        customerName: 'Name primärer Teilnehmer',
        customerPhone: 'Kunden Telefon',
        roomIdentifier: 'Raum',
        allowCustomerUpload: 'Kunden Upload erlaubt',
      },
    },
    grid: {
      toolbar: {
        create: 'Meeting erstellen',
      },
      noDate: 'Ohne Termin',
      from: 'Termin',
      identifier: 'Raum',
      customerName: 'Kunde',
      type: 'Auftragstyp',
      sendSms: 'SMS gesendet',
      sendEmail: 'E-Mail gesendet',
      hasPosition: 'Position verfügbar',
      customerPhone: 'Telefonnummer Kunde',
      customerEmail: 'E-Mail Kunde',
      contactPersonName: 'Kontaktperson',
    },
    linkInfoDialog: {
      title: 'Der Link wurde in die Zwischenablage kopiert',
      note: 'Bitte beachten Sie, dass Links nur für das Aussenden an den Kunden gedacht sind.',
      confirm: 'Alles klar',
    },
  },
  roomPlaner: {
    grid: {
      title: 'Titel',
      description: 'Beschreibung',
      size: 'Größe',
      length: 'Länge',
      width: 'Breite',
      height: 'Höhe',
      sum: 'Summe',
      selectedServices: 'Gewählte Gewerke: ',
      toolbar: {
        create: 'Neuer Raum',
        createCategory: 'Neue Kategorie',
        settings: {
          transferData: 'Raumdaten in die Kalkulation übertragen',
          syncMagicplan: 'Magicplan synchronisieren',
          exportJson: 'Daten als JSON exportieren',
        },
      },
    },
    dialogs: {
      create: {
        title: 'Eintrag anlegen',
        note: 'Hier können Sie weitere Räume anlegen.',
        labels: {
          fieldSet: 'Create item',
          title: 'Titel',
          description: 'Beschreibung',
          size: 'Größe in m²',
          category: 'Kategorie',
          length: 'Länge in m',
          width: 'Breite in m',
          height: 'Höhe in m',
          serviceCatalogItems: 'Leistungen',
        },
      },
      update: {
        title: 'Eintrag bearbeiten',
        note: 'Hier können Sie den aktuellen Raum bearbeiten.',
      },
      delete: {
        title: 'Eintrag löschen',
        message: 'Möchten Sie diesen Eintrag wirklich löschen?',
      },
      transferData: {
        title: 'Raumdaten übernehmen',
        description:
          'Damit können Sie Raumdaten direkt als Kategorien in die Kalkulation übernehmen',
        useServiceCatalogCategories: 'Übernehme vorgemerkte Servicekatalog Einträge',
      },
    },
  },
  appointment: {
    create: 'Termin',
  },
  noteDialog: {
    title: 'Anmerkung für "{{title}}"',
    note: 'Geben Sie eine beliebige Freitext-Notiz für die gewählte Frage an.',
    buttonLabel: 'Notiz',
    startRecording: 'Spracheingabe starten',
    stopRecording: 'Spracheingabe stoppen',
  },
  subTaskDialog: {
    title: {
      label: 'Unteraufgabe erstellen',
      title: 'Titel',
      description: 'Beschreibung',
      finishDateDefaultDays: 'Fälligkeitsdatum (in Tagen)',
    },
    note: 'Hier können Sie weitere Unteraufgaben erstellen.',
    due: '({{ date }})',
    today: 'Heute',
    editTitle: 'Aufgabe editieren',
    editNote: 'Hier können Sie die Unteraufgabe bearbeiten.',
    update: 'Augabe speichern',
    edit: {
      success: 'Erfolgreich gespeichert',
      error: 'Aufgabe wurde nicht gespeichert!',
    },
    add1day: '1 Tag',
    add3days: '3 Tage',
    add7days: '7 Tage',
    add14days: '14 Tage',
    file: {
      label: 'Verknüpfte Datei',
      selectFile: 'Datei verknüpfen',
    },
  },
  subTaskFileDialog: {
    title: 'Datei verknüpfen',
    note: 'Verknüpfen Sie eine Datei mit dieser Aufgabe.',
    submit: 'Auswahl verknüpfen',
  },
  subTaskExportDialog: {
    title: 'Aufgaben exportieren',
    note: 'Laden Sie die exportierten Aufgaben herunter.',
  },
  subTaskGroup: {
    new: 'New',
    task: 'Auftrag',
    created_at: 'Erstellt am',
    title: 'Titel',
    description: 'Beschreibung',
    controller: 'Verantwortlicher',
    dueDate: 'Zu erledigen bis',
    completedDate: 'Erledigt am',
    actions: 'Aktionen',
    inProgress: 'In Bearbeitung',
    completed: 'Abgeschlossen',
    status: 'Status',
    assignedUser: 'Zugewiesen an',
    userAssignment: 'Bitte wählen Sie, wer die Aufgabe erledigen soll:',
    create: 'Neue Aufgabe',
    search: 'Suchen',
    taskId: 'Auftrags ID',
    undone: 'Als offen markieren',
    done: 'Als abgeschlossen markieren',
    edit: 'Editieren',
    toDo: 'Aufgabe öffnen',
    review: 'Zum Überprüfen markieren',
    reopen: 'Zurück auf offen',
    delete: 'Löschen',
    typeId: 'Service Level Aufgabe',
    showSlaSubTasks: 'SLA einblenden',
    assignee: 'Zuweisung',
  },
  logs: {
    list: {
      header: {
        date: 'Datum & Zeit',
        from: 'Von',
        message: 'Mitteilung',
        state: 'Status',
      },
      initiator: {
        system: 'System',
      },
    },
    dialogs: {
      create: {
        title: 'Eintrag erstellen',
        note: 'Hier können Sie neuen Eintrag anlegen.',
        labels: {
          title: 'Eintrag',
        },
      },
    },
    sms: "Gesendete Nachricht: '{{message}}'",
  },
  errors: {
    not_found: {
      title: 'Auftrag nicht gefunden',
      note: 'Der von Ihnen angeforderte Auftrag wurde nicht gefunden',
    },
    not_activated: {
      title: 'Auftrag nicht freigeschaltet',
      note: 'Der Auftrag wurde angelegt und Ihnen zugewiesen.\nBislang liegt vom Administrator noch keine Freigabe des Auftrags vor.\nSobald diese vorliegt, können Sie den Auftrag einsehen.',
    },
  },
  contextMenu: {
    title: 'Erweitert',
    copyFormData: 'Formulardaten von einem anderen Auftrag einfügen',
    compareFormData: 'Formulardaten mit einem anderen Auftrag vergleichen',
    changeTemplate: 'Formular ändern',
    createMessage: 'Nachricht erstellen',
    createTaskLog: 'Eintrag erstellen',
  },
  jumpMenu: {
    title: 'Springe zu',
  },
  copyFormDataDialog: {
    title: 'Formulardaten von einem anderen Auftrag',
    submit: 'Einfügen',
    preview: 'Aktuelle Formulardaten im neuen Tab ansehen',
    warning:
      'Diese Funktion dient dazu, dass man ähnlichen Fragen eines anderes Auftrag hier einfügen kann. Sofern das Formular im Quell- und Zielauftrag unterschiedlich ist, werden nur die Antworten mit dem selben Identifier über beide Formulare überschrieben. Achtung, durch das ausführen löschen Sie womöglich bereits getätigte Eingaben in diesem Formular. Möchten Sie fortfahren?',
    labels: {
      from: 'Quellauftrag wählen',
    },
  },
  sortImagesDialog: {
    title: 'Bilder sortieren',
  },
  sortDocumentsDialog: {
    title: 'Dokumente sortieren',
  },

  copyQuestionDialog: {
    buttonLabel: 'Fragen kopieren',
    title: 'Fragen kopieren',
    submit: 'Kopieren',
    labels: {
      template: 'Formular',
    },
  },
  viewImagesDialog: {
    title: 'Bildergalerie',
  },
  createSatelliteImageDialog: {
    title: 'Satellitenbild erzeugen',
    description:
      'Hier können Sie ein Satellitenfoto von einer Adresse erzeugen. Darauf können Sie Elemente auch einzeichnen.',
    success: 'Satellitenbild wurde erstellt.',
  },
  satelliteImageAddressDialog: {
    title: 'Adresse aktualisieren',
    submit: 'Aktualisieren',
    geolocateError: 'Für diese Adresse wurden keine Koordinaten gefunden.',
  },
  satelliteImageDescription: {
    titleRow: 'Satellitenbild von {{address}}',
    areaRow: 'Objekt {{num}}: {{area}}',
  },
  emails: {
    downloadAllAttachments: 'Alle Anlagen ({{count}}) herunterladen',
    to: 'An: ',
    cc: 'CC:',
    bcc: 'Bcc:',
    from: 'Von: ',
    state: {
      scheduled: 'wird gesendet...',
      delivered: 'gesendet',
      failed: 'fehlgeschlagen.',
    },
  },
};
